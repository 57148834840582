body {
    margin: 0;
    font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#root {
    height: 100vh;
    font-family: var(--fontStack) !important;
    overflow: hidden;
}

div[role="group"][tabindex] {
    height: 100%;
}

::-webkit-scrollbar {
    width: 20px;
}

::-webkit-scrollbar-thumb {
    background-color: #c4cacf;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
}
